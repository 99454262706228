$(function listiniIndex() {

    var params = [];

    params['id'] = $('#id').val();
    params['anagrafica_id'] = $('#anagrafica_id').val();
    params['cer_id'] = $('#cer_id').val();
    params['provincia_sigla'] = $('#provincia').val();
    params['principale_id'] = $('#principale_id').val();

    // params = params.filter(param => param.length > 0);

    var table = $('#listino-index').DataTable({
        dom: "Bfrtlip",
        searching: true,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "Tutte"]],
        processing: true,
        serverSide: true,
        ajax: {
            url: $('#listino-index').data('url'),
            method: 'get',
            data: params,
        },
        columns: [
            {
                data: 'id', name: 'Id', searchable: true, orderable: true
            },
            { data: 'cer_id', name: 'Cer_id', searchable: true },
            { data: 'suffisso', name: 'suffisso', searchable: false },
            { data: 'tipo', name: 'Tipo', searchable: true, orderable: true },
            { data: 'valore', name: 'Valore', searchable: true, orderable: true },
            { data: 'provincia_sigla', name: 'Provincia_sigla', searchable: true },
            { data: 'imponibile_minimo', name: 'Imponibile_minimo', searchable: true },
            { data: 'valore_forfait', name: 'Valore_forfait', searchable: true },
            { data: 'anagrafica_id', name: 'Anagrafica_id', searchable: true },
            { data: 'principale_id', name: 'Principale_id', searchable: false, orderable: false},
            { data: 'start_date', name: 'Start_date', searchable: true },
            { data: 'end_date', name: 'End_date', searchable: true },
            { data: 'stato', name: 'Stato', searchable: false},
            {
                data: 'action',
                name: 'azioni',
                searchable: false
            },
        ],
        initComplete: function () {
            this.api().columns().every(function () {
                var column = this;
                var input = document.createElement("input");
                $(input).appendTo($(column.footer()).empty())
                .on('change', function () {
                    column.search($(this).val(), false, false, true).draw();
                });
            });
        },
        buttons: [
            {
                extend: 'copy',
                text: 'Copia',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'excel',
                text: 'Excel',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
            {
                extend: 'pdf',
                text: 'PDF',
                className: 'btn btn-default',
                exportOptions: {
                    columns: 'th:not(:last-child)'
                }
            },
        ],
        responsive: true,
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
        }
    });
});


$('#listini-infoBtn').on('click', function() {
    $('#listini-infoPanel').toggle('slow');
})