$('#cer_id').on('change', function () {
    var cer = $('#cer_id');
    var litio = false;
    var selected = cer.find(':selected').filter( function () {
        var codice = $(this).data('codice');
        if (codice == '160605') {
            litio = true;
        }

        if (litio) {
            $('#battery_color_container').show();
        } else {
            $('#battery_color_container').hide();
        }
    });
})


$(function () {
    var cer = $('#cer_id');
    var litio = false;
    var selected = cer.find(':selected').filter( function () {
        var codice = $(this).data('codice');
        if (codice == '160605') {
            litio = true;
        }

        if (litio) {
            $('#battery_color_container').show();
        } else {
            $('#battery_color_container').hide();
        }
    });
});