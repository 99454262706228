import { Loader } from "@googlemaps/js-api-loader";


$('body.ftv.ftv-italyMap').each(() => {

  const gmLoader = new Loader({
    apiKey: document.head.querySelector('meta[name="google-apikey"]').content,
    version: "weekly",
  });
  const mapStyle = [
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];
  let infowindow;

  function showInfoModal(title, body, btnOkCbk) {
    const modal = $('#infoModal');
    modal.find('.modal-title').text(title);
    modal.find('.modal-body').html(body);
    if (btnOkCbk) {
      modal.find('.btnOk').one(function () {
        btnOkCbk(modal);
      })
        .show();
    } else {
      modal.find('.btnOk').hide();
    }
    return modal.modal('show');
  }

  $('#italyMap').each(function () {
    gmLoader.load().then(() => {
      // 42.1927027,13.4606529
      const position = { lat: 42.0927027, lng: 12.8406529 },
            bounds = {north: 47.092452, south: 36.338404, west: 6.437519, east: 18.745526};

      // Create map
      const map = new google.maps.Map(document.getElementById("italyMap"), {
        center: position,
        zoom: 6,
        styles: mapStyle,
        restriction: {
            latLngBounds: bounds
        }
        // mapTypeId: 'terrain'
      });
    //   map.fitBounds(bounds)

      map.data.loadGeoJson(
        "/maps/regioni.geojson",
        { idPropertyName: "NOME_REG" }
      );

      map.data.setStyle(styleFeature);
      // set up the style rules and events for google.maps.Data
      map.data.addListener('mouseover', mouseInToRegion);
      map.data.addListener('mouseout', mouseOutOfRegion);
      map.data.addListener('mouseout', () => { infowindow.close(); });
      infowindow = new google.maps.InfoWindow({ pixelOffset: { height: -4 } });

      map.data.addListener('mousemove', (e) => {
        // const pos = new google.maps.LatLng(e.latLng);
        const regione = e.feature.getProperty('NOME_REG');
        const tot_moduli = e.feature.getProperty('tot_moduli');
        var content = '<p>' + regione + ': ' + tot_moduli.toLocaleString() + ' moduli</p>';
        // if (detail_url) {
        //   content += '<a class="btn btn-sm btn-outline-primary" href="' + detail_url + '">Dettaglio regione</a>';
        // }

        infowindow.setContent(content);
        infowindow.setPosition(e.latLng);
        infowindow.open(map);
      });

      map.data.addListener('click', (e) => {
        const detail_url = e.feature.getProperty('detail_url');
        if (detail_url) {
          window.location = detail_url;
        }
      });

      // Show marker
      // const marker = new google.maps.Marker({
      //   position: position,
      //   map: map,
      //   title: `${position.lat}, ${position.lng}`
      // });
      window['GMAP'] = map;
    });

  });

  function styleFeature(feature) {
    var low = [3, 10, 80];  // color of smallest datum
    var high = [5, 100, 50];   // color of largest datum
    const regione = feature.getProperty('NOME_REG').toLowerCase();
    // console.log(regione);

    const regioneObj = MODULI_PER_REGIONE.regioni[regione] || {};
    const tot_moduli = regioneObj.tot || 0;
    const detail_url = regioneObj.detail_url;

    feature.setProperty('tot_moduli', tot_moduli);
    feature.setProperty('detail_url', detail_url);

    // delta represents where the value sits between the min and max
    var delta = (tot_moduli - MODULI_PER_REGIONE.min) /
      (MODULI_PER_REGIONE.max - MODULI_PER_REGIONE.min);

    // console.log('regione', regione, 'tot_moduli', tot_moduli, 'delta', delta);
    var color = [];
    for (var i = 0; i < 3; i++) {
      // calculate an integer color based on the delta
      color[i] = (high[i] - low[i]) * delta + low[i];
    }

    var outlineWeight = 0.5, zIndex = 1;
    if (feature.getProperty('state') === 'hover') {
      outlineWeight = zIndex = 2;
    }

    return {
      strokeWeight: outlineWeight,
      strokeColor: '#fff',
      zIndex: zIndex,
      fillColor: 'hsl(' + color[0] + ',' + color[1] + '%,' + color[2] + '%)',
      fillOpacity: 0.75,
      // visible: showRow
    };
  }

  function mouseInToRegion(e) {
    // console.log('IN', e.feature);
    e.feature.setProperty('state', 'hover');
  }

  function mouseOutOfRegion(e) {
    // console.log('OUT', e.feature);
    e.feature.setProperty('state', 'normal');
  }

  function getFullAddress() {
    const addressComponents = [];

    ['#indirizzo', '#numero_civico', '#provincia', '#cap', '#comune'].forEach(elementId => {
      const value = $(elementId).val();
      if (value) {
        addressComponents.push(value);
      }
    });

    return addressComponents.join(',');
  }

  $('#addressGeocodingMap').each(function () {
    // console.log('x1');
    gmLoader.load().then(() => {
      // console.log('x2');

      // Init Geocoder
      const GEOCODER = new google.maps.Geocoder();
      console.log('GEOCODER', GEOCODER);

      // Init Map
      const map = new google.maps.Map(document.getElementById("addressGeocodingMap"), {
        center: { lat: 45, lng: 45 },
        zoom: 1,
      });

      let posMarker;

      $('.btnValidaIndirizzo').on('click', function () {
        const searchAddress = getFullAddress();
        if (searchAddress === '') {
          return;
        }
        GEOCODER.geocode({
          address: searchAddress,
        }, (results, status) => {
          console.log(status, results);
          if (status === 'OK') {
            const location = results[0].geometry.location;

            $('#lat').val(location.lat);
            $('#lng').val(location.lng);
            if (!posMarker) {
              posMarker = new google.maps.Marker({
                position: location,
                map: map,
                title: `${location.lat}, ${location.lng}`,
                draggable: true
              });
              posMarker.addListener('dragend', () => {
                const position = posMarker.getPosition();
                $('#lat').val(position.lat());
                $('#lng').val(position.lng());
              })
            } else {
              // move marker
              posMarker.setPosition(location);
              posMarker.setTitle(`${location.lat}, ${location.lng}`);
            }
            map.panTo(location);
            map.setZoom(15);
          }
        });
      });

    });
  });

  $('#produttore_piva').on('keyup', async function () {
    const fieldElem = $(this);
    const piva = fieldElem.val();
    console.log('check piva', piva);
    if (piva.length > 10) {
      fieldElem.removeClass('is-invalid is-valid').addClass('border-warning');
      const url = VERIFY_PIVA_URL.replace('==PIVA==', piva);
      const result = await axios.get(url);

      const res = result.data;
      if (res) {
        console.log(res);
        if (res.result === 'OK') {
          fieldElem.removeClass('border-warning').addClass('is-valid');
          $('#produttore_ragioneSociale').val(res.data.ragioneSociale);
        } else {
          fieldElem.removeClass('border-warning').addClass('is-invalid');
          $('#produttore_ragioneSociale').val('- non trovato -');
        }
      }
    }
  });

  $('.btnCancelActivation').on('click', async function () {
    const url = $(this).data('url');
    // TODO - Use a fancy and beautiful modal
    const res = window.confirm("ATTENZIONE. Procedere all'annullamento dell'attivazione?");
    if (res) {
      // console.log(url);
      const result = await axios.delete(url);
      // console.log(result);
      if (result.data.result === 'OK') {
        window.location.reload();
      }
    }

  });

});
